<template>
  <div class="app-bg"></div>

  <!--<div class="universalTitle">FAQs</div>-->
  
    <div class="faq-container">
      <div class="leftFAQ ">
        <h5 class = "faq-title1">FAQs</h5>
        <h1  class = "faq-title">Have any Questions?</h1>
  
        <p class = "faq-contact">
        In case you have more, you can contact us at 
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=misaimsummit@gmail.com"
          style="color: black; text-decoration: underline;"
          target="_blank">
          misaimsummit@gmail.com
          </a>
        </p>
        <div id="faqImageContainer">
        <img src="../assets/images/2024FAQ.png" alt="" id="2024FAQ"/>
        </div>
      </div>
      <div class="rightFAQ">
        <ul class="faq">
          <li>
            <input id="faq-a" type="checkbox" name="faq" />
            <label for="faq-a" class="universalSubheader faq-heading"
              >Who can join the IM Summit?</label
  >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  All undergraduate students from across the Philippines are
                  eligible to sign up for the event.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-b" type="checkbox" name="faq" />
            <label for="faq-b" class="universalSubheader faq-heading"
              >What if I'm not an IT major?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  That’s okay! IM Summit is open to all undergraduate students
                  from all majors who are interested in learning and creating IT
                  solutions. This event will equip you with the opportunity to
                  experience real-life business cases under the guidance of
                  business-minded tech enthusiasts.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-c" type="checkbox" name="faq" />
            <label for="faq-c" class="universalSubheader faq-heading"
              >How many members should the team be made up of?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Your team may consist of a minimum of 3 members and a maximum of
                  5 members with the team leader and backup team leader being part
                  of the 3-5 members.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-d" type="checkbox" name="faq" />
            <label for="faq-d" class="universalSubheader faq-heading"
              >Do all teammates have to come from the same university?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  No, a team can be made up of students from different
                  universities. Team members may also be from different year
                  levels and majors as long as all members of the team are
                  undergraduate college students.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-e" type="checkbox" name="faq" />
            <label for="faq-e" class="universalSubheader faq-heading"
              >Can a participant be a part of multiple teams?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  No. Each participant must belong to only one team.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-f" type="checkbox" name="faq" />
            <label for="faq-f" class="universalSubheader faq-heading"
              >Do I have to be in Manila during the IM Summit?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                Day 1 will be held completely online via Zoom, 
                so you don't necessarily have to be in Manila! 
                However, you would have to be present for Day 2 and Day 3 of IM Summit 
                which will be held onsite on April 27 and May 4, 2024, at Ateneo de Manila University. 
                More details about the venue will be announced soon, so stay updated!
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-g" type="checkbox" name="faq" />
            <label for="faq-g" class="universalSubheader faq-heading"
              >After submitting our registration, are we allowed to add more
              members?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  No. As a general rule, more members cannot be added to the
                  lineup. However, substitutions before the event proper are
                  allowed. In any case of member lineup changes, contact
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>
                  immediately.
                </p>
              </li>
            </ul>
          </li>
          <!--<li>
            <input id="faq-h" type="checkbox" name="faq" />
            <label for="faq-h" class="universalSubheader faq-heading"
              >How do we apply?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Your team may register through our official website:
                  <a href="https://imsummit.misa.org.ph"
                    >https://imsummit.misa.org.ph</a
                  >. After registration, wait for an email confirmation with the
                  payment method details. Pay the registration fee and send a
                  screenshot for proof of transaction to
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>.
                  Lastly, wait for the email confirmation with the official
                  receipt.
                </p>
              </li>
            </ul>
          </li>-->
          <li>
            <input id="faq-i" type="checkbox" name="faq" />
            <label for="faq-i" class="universalSubheader faq-heading"
              >Is the registration fee different depending on how many people are
              on the team?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  The registration fee will always be Php 250 per person for early bird applicants and Php 300 per person 
                  for regular applicants regardless of the number of members on your team. 
                  This fee is both non-refundable and non-transferable to other teams 
                  and inclusive of food during the onsite event days. 
                  The payment details will be sent after registration.

                </p>
              </li>
            </ul>
          </li>
         <!-- <li>
            <input id="faq-j" type="checkbox" name="faq" />
            <label for="faq-j" class="universalSubheader faq-heading"
              >When is the application deadline for the IM Summit?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  For the Early Bird Application period:<br />
                  February 13 - February 17<br />
                  For the Regular Application period:<br />
                  February 18, - February 24
                </p>
              </li>
            </ul>
          </li> -->
          <!--<li>
            <input id="faq-k" type="checkbox" name="faq" />
            <label for="faq-k" class="universalSubheader faq-heading"
              >When is the deadline for the Registration Fee Payment?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Payment Cut-off Date: February 28 (5:00 PM)
                </p>
              </li>
            </ul>
          </li>-->
          <!--<li>
            <input id="faq-l" type="checkbox" name="faq" />
            <label for="faq-l" class="universalSubheader faq-heading"
              >What is the Official Schedule of the IM Summit?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  First Day: March 4, 2023 (1:30PM - 4:30PM) <br />
                  Second Day: March 18, 2023 (1:30PM - 6:00PM) <br />
                  Third Day: March 31, 2023 (12:30PM - 5:00PM) <br />
                </p>
              </li>
            </ul>
          </li>-->
          <!--<li>
            <input id="faq-m" type="checkbox" name="faq" />
            <label for="faq-m" class="universalSubheader faq-heading"
              >What are the internet requirements?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  According to
                  <a
                    href="https://support.zoom.us/hc/en-us/articles/201362023-System-Requirements-for-PC-Mac-and-Linux?kbid=152096"
                    >Zoom’s bandwidth requirements</a
                  >, 1.5-3.0Mbps is the recommended download speed for group
                  meetings and 800kbps-3.0Mbps for upload speed. You can check
                  your internet speed at
                  <a href="https://www.speedtest.net"
                    >https://www.speedtest.net</a
                  >
                  or <a href="https://fast.com">https://fast.com</a>. Also, take
                  note that Zoom uses roughly
                  <a
                    href="https://www.reviews.org/internet-service/how-much-data-does-zoom-use/#:~:text=2.4%20GB%2Fhr.,used%20for%20other%20everyday%20activities."
                    >810MB-2.4 GB per hour for group meetings</a
                  >.
                </p>
              </li>
            </ul>
          </li>-->
          <li>
            <input id="faq-n" type="checkbox" name="faq" />
            <label for="faq-n" class="universalSubheader faq-heading"
              >Do I have to attend talks to participate? What if someone from the
              team isn’t available during a specific time?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  At least half of the participating members of the team have to
                  be present during the talks. Should you be unavailable to attend
                  the talks due to valid reasons, you may reach out to us via
                  email at
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-o" type="checkbox" name="faq" />
            <label for="faq-o" class="universalSubheader faq-heading"
              >Are we allowed to leave early?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  As long as you have a valid reason for leaving (such as an
                  emergency or prior commitment), please notify us at
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>.
                </p>
              </li>
            </ul>
          </li>
          <!--<li>
            <input id="faq-p" type="checkbox" name="faq" />
            <label for="faq-p" class="universalSubheader faq-heading"
              >What if internet connection issues happen during the
              presentation?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Try to reconnect as soon as possible, and you’ll be transferred
                  to your respective breakout room for the presentation.
                </p>
              </li>
            </ul>
          </li>-->
          <!--<li>
            <input id="faq-q" type="checkbox" name="faq" />
            <label for="faq-q" class="universalSubheader faq-heading"
              >Will the sessions be recorded?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Yes, but the recorded session shall only be given upon a valid
                  request. You may send an email to
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>.
                </p>
              </li>
            </ul>
          </li>-->
          <li>
            <input id="faq-r" type="checkbox" name="faq" />
            <label for="faq-r" class="universalSubheader faq-heading"
              >Where can I get updates about the IM Summit?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  Announcements and updates will be sent via email to
                  participating members. You may also see general announcements on
                  <a href="https://www.facebook.com/Ateneo.MISA"
                    >https://www.facebook.com/Ateneo.MISA
                  </a>
                  or
                  <a href="https://www.facebook.com/MISAIMSummit"
                    >https://www.facebook.com/MISAIMSummit</a
                  >
                  and on our official website
                  <a href="https://imsummit.misa.org.ph"
                    >https://imsummit.misa.org.ph</a
                  >.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <input id="faq-s" type="checkbox" name="faq" />
            <label for="faq-s" class="universalSubheader faq-heading"
              >Who can I contact if I have any concerns or further
              questions?</label
            >
            <ul class="faq-box">
              <li>
                <p class="universalBody faq-text">
                  All inquiries and clarifications must be coursed through
                  <a href="misaimsummit@gmail.com">misaimsummit@gmail.com</a>.
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

</template>

<style scoped>
.rightFAQ {
  flex: 1;
  padding: 30px;
  background-color: transparent;
  margin: 20px;
  border-radius: 5px;
  max-width: 100%;
  max-height: fit-content;
  overflow: hidden;
  box-sizing: border-box;
}


.faq-container {
  display: flex;
  justify-content: space-between;
}


input[type="checkbox"] {
  filter: grayscale() 100%;
}
.universalBody {
  font-size: 1rem; /* Adjust base font size */
  word-wrap: break-word; /* Break words that are too long */
}
.universalTitle {
  color: #ffffff;
  line-height: 183.81px;
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 14vw;
  text-shadow: 0 0 8px #fff;

  font-family: var(--font-header);
  font-size: calc(16px + 12.5vw);
  font-weight: 600;
  text-align: center;
}

.universalSubheader {
  font-size: 26px;
  font-size: calc(16px + 1vw);
  font-weight: 600;
}

.universalBody {
  font-size: calc(16px + 0.5vw);
  text-align: justify;
  font-weight: 400;
}

.faq-heading {
  font-size: calc(16px + 0.5vw);
  color: #3a3a3c;
  padding-left: 2vw;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.faq-text {
  font-size: calc(16px + 0.3vw);
  color: #ffffff;
  width: 95%;
  padding-left: 1vw;
  padding-top: 1vh;
  text-align: left;
  word-wrap: break-word;
}

ul.faq {
  width: 100%;
  
  padding-left: 0;
  color: #ffffff;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ul.faq > li {
  list-style-type: none;
  position: relative;
  width: 100%;
  background: rgb(250, 250, 250);
  margin-bottom: 2.5vh; /* adjust accordingly  */
  word-wrap: break-word;
  overflow-wrap: break-word;
  
}

label {
  position: relative;
  display: block;
  padding-top: 2vh;
  padding-bottom: 2vh;
  transition: background 0.3s;
  cursor: pointer;
}

label:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  right: 5px;
  width: 0;
  height: 0;
  border-top: 4px solid rgba(250, 250, 250, 1);
  border-bottom: 0 solid rgba(250, 250, 250, 1);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transition: border-bottom 0.1s, border-top 0.1s 0.1s;
}

label:hover,
input:checked ~ label {
  background: #ffffff;
  color: #241c4a;
}

input:checked ~ label:after {
  border-top: 0 solid rgba(255, 255, 255, 0.5);
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  transition: border-top 0.1s, border-bottom 0.1s 0.1s;
}

input {
  display: none;
}

input:checked ~ ul.faq-box {
  max-height: 300px;
  transition: max-height 0.5s ease-in;
}

ul.faq-box {
  width: auto;
  max-height: 0;
  padding: 0 1vw;
  overflow: hidden;
  list-style-type: none;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  transition: max-height 0.5s ease-out;
  min-width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  
  
}

ul.faq-box li p {
  display: block;
  padding: 12px;
  color: #525a5f;
  text-decoration: none;
  transition: background 0.3s;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-height: fit-content;
  margin:0;
}


::-webkit-scrollbar {
  display: none;
}


.faq-title1 {
  color: #F452C1; 
  font-size: 16px; 
  margin: 0 0 0px 20px; 
  font-weight: bold; 
}
.faq-title {
  color: #221B35; 
  font-size: 52px; 
  margin: 0 0 10px 20px; 
  font-weight: bold; 
}

.faq-contact {
  color: #000000; 
  font-size: 20px; 
  margin: 0 0 10px 20px; 
}
.faq-email {
  color: #0000EE; /* Standard link color */
  text-decoration: none; /* Removes the underline from the link */
}

.faq-email:hover {
  text-decoration: underline; /* Adds underline on hover for better user interaction */
}

.leftFAQ {
  flex: 1;
  padding-top: 30px;
  padding-bottom: 4rem;
  background-color: transparent;
  margin: 20px;
  border-radius: 5px;
}


/*Mobile Devices */
@media (max-width: 480px) {
  .Topside {
    height: 25vh;
  }
  
  #faqImageContainer {
    display: none;
  }
  .faq-text {
    font-size: calc(12px + 0.3vw);
  }

  .faq-container {
    flex-direction: column;
  }
  .leftFAQ, .rightFAQ {
    padding: 15px;
    margin: 0; 
    width: 100%; /* Full width on small screens */
  }
  .faq-title {
    font-size: 1.5rem; /* Adjust title size for small screens */
  }
  .faq-contact, .faq-email {
    font-size: 0.875rem; /* Adjust contact info size */
  }
  
}

/*Ipad Portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .Topside {
    height: 25vh;
  }
  .faq-text {
    font-size: calc(12px + 0.3vw);
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .Topside {
    height: 25vh;
  }
  .faq-text {
    font-size: calc(12px + 0.3vw);
  }
}
</style>

<style scoped>
.app-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  min-height: 100%;
  background: white;
}
</style>
